export const PATHS = {
  home: "/",
  frontpage: "/home",
  about: "/about",
  services: "/services",
  portfolio: "/portfolio",
  joinUs: "/join-us",
  contactUs: "/contact-us",
  portfolioInfo: "/project-info",
  serviceInfo: "/service-info",
  notFoundPage: "*",
  noDataFoundPage: "/no-data",
};

export const kontentVariables = {
  homePage: "home_page",
  aboutusPage: "about_page",
  portfolioPage: "portfolio_page",
  portfolioInfoContentType: "portfolio_info",
  servicesPage: "services_page",
  serviceCategoriesContentType: "service_categories",  
  serviceByCategoryInfoContentType: "service_content_info",
  serviceCategoryField: "service_category",
  serviceTaxonomies: "service_categories",
  successStoriesPage: "success_stories_section",
  whatMakesusContentType: "what_makes_us",
  joinUsTribeContentType: "join_our_tribe",
  joinusPage: "join_us_page",
  jobRoleContentType: "openings_type",
  jobOpeningsContentType: "openings",
  contactUsPage: "contact_us_page",
  headerPage: "header",
  footerPage: "footer",
  servicesContentType: "service_info",  //service_info content type is not so important
};
